































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { IUser, IStatistic, Statistic, StatisticIntervalType } from '@/models';
import DownloadsChart from '@/components/charts/DownloadsChart.vue';
import StatusChart from '@/components/charts/StatusChart.vue';
import AndroidChart from '@/components/charts/AndroidChart.vue';
import IOSChart from '@/components/charts/IosChart.vue';
import WebChart from '@/components/charts/WebChart.vue';
import {
  calculateDeviceChartsData,
  ChartSeries,
  NumberSeries
} from '@/data/devicedata';

export default Vue.extend({
  components: {
    DownloadsChart,
    StatusChart,
    AndroidChart,
    IOSChart,
    WebChart
  },
  name: 'Dashboard',
  data() {
    return {
      deviceData: {} as ChartSeries,
      deviceTotals: {} as NumberSeries,
      fetchStatsParams: {},
      androidList: [] as number[],
      iosList: [] as number[],
      webList: [] as number[],
      statistics: new Statistic(),
      androidCount: 0,
      iosCount: 0,
      webCount: 0,
      active: 0,
      inactive: 0,
      totalDevices: 0
    };
  },
  computed: {
    ...mapGetters({
      getThemeMode: 'getThemeMode',
      getUser: 'auth/getUser',
      getStats: 'statistic/getStatistics'
    }),
    user(): IUser {
      return this.getUser;
    },
    stats(): IStatistic {
      return this.getStats;
    }
  },

  async mounted() {
    if (!this.user) {
      this.fetchUser();
    }
    const currentYear = new Date().getFullYear();
    const fetchStatsParams = {
      created_after: new Date(currentYear, 0, 1).toISOString().replace('Z', ''),
      created_before: new Date(currentYear + 1, 0, 30)
        .toISOString()
        .replace('Z', ''),
      interval: StatisticIntervalType.MONTHLY
    };
    await this.fetchStats(fetchStatsParams);
    this.statistics = this.stats;
    await this.setData();
  },
  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser',
      fetchStats: 'statistic/fetchStatistics'
    }),
    setData() {
      this.androidCount = this.statistics.android;
      this.iosCount = this.statistics.ios;
      this.webCount = this.statistics.web;
      this.active = this.statistics.active;
      this.inactive = this.statistics.inactive;
      this.totalDevices = this.androidCount + this.iosCount + this.webCount;
      this.deviceTotals = {
        android: this.androidCount,
        ios: this.iosCount,
        web: this.webCount
      };
      this.deviceData = calculateDeviceChartsData(this.statistics);
    }
  }
});
