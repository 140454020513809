import { DownloadsChart, YearDownloadsChart } from './chartTypes';

export const weekBarChart: DownloadsChart = {
  series: [
    {
      name: 'Android',
      data: [0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'IOS',
      data: [0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'Web',
      data: [0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    colors: ['#2196F3', '#FF5252', '#4CAF50'],
    chart: {
      type: 'bar',

      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10px'
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri ', 'Sat']
    },
    yaxis: {
      show: false
    },
    grid: {
      borderColor: '#fff',
      show: false
    },
    legend: {
      horizontalAlign: 'start',
      position: 'top',
      offsetY: 0,
      markers: {
        radius: 50
      }
    },
    fill: {
      opacity: 1
    }
  }
};

export const monthsBarChart: DownloadsChart = {
  series: [
    {
      name: 'Android',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'IOS',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'Web',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    colors: ['#2196F3', '#FF5252', '#4CAF50'],
    chart: {
      type: 'bar',

      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10px'
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yaxis: {
      show: false
    },
    grid: {
      borderColor: '#fff',
      show: false
    },
    legend: {
      horizontalAlign: 'start',
      position: 'top',
      offsetY: 0,
      markers: {
        radius: 50
      }
    },
    fill: {
      opacity: 1
    }
  }
};

export const yearBarChart: YearDownloadsChart = {
  series: [
    {
      name: 'App Downloads',
      data: [0, 0, 0]
    }
  ],
  chartOptions: {
    colors: ['#2196F3', '#FF5252', '#4CAF50'],
    chart: {
      type: 'bar',

      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10px'
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: ['Android', 'IOS', 'Web']
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: function (val: number): number {
          return val;
        }
      }
    },
    grid: {
      borderColor: '#fff',
      show: false
    },
    legend: {
      horizontalAlign: 'start',
      position: 'top',
      offsetY: 0,
      markers: {
        radius: 50
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true
    },
    title: {
      text: 'Monthly Inflation in Argentina, 2002',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  }
};
