import { StatusChart } from './chartTypes';
export const statusChart: StatusChart = {
  series: [0, 0],
  chartOptions: {
    labels: ['Active', 'Inactive'],
    legend: {
      show: false
    },
    colors: ['#4CAF50', '#FF5252'],
    chart: {
      type: 'donut',
      width: '80%'
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true,
              fontSize: '15px',
              fontWeight: 700,
              offsetY: 5
            }
          },
          size: '80%'
        }
      }
    },
    responsive: [
      {
        breakpoint: 250,
        options: {
          chart: {
            width: '110%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
};
