


















import { statusChart } from '@/data/charts/statusChart';
import Vue from 'vue';
export default Vue.extend({
  name: 'StatusChart',
  props: {
    active: Number,
    inactive: Number
  },
  data() {
    return { statusChart: statusChart };
  },
  created() {
    this.updateSeries();
  },
  mounted() {
    this.updateSeries();
  },
  methods: {
    updateSeries() {
      const activeDevices = this.active as number;
      this.statusChart.series = [activeDevices, this.inactive];
    }
  }
});
