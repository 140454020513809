










import { iosAreaChart } from '@/data/charts/sparklineCharts';
import Vue from 'vue';
export default Vue.extend({
  name: 'IOSChart',
  props: {
    deviceData: [],
    totalDownloads: Number
  },
  data() {
    return { iosAreaChart: iosAreaChart };
  },
  created() {
    this.updateChartTitle();
  },
  mounted() {
    this.updateChartTitle();
  },
  methods: {
    updateChartTitle() {
      const iosTotal = this.totalDownloads as number;
      this.iosAreaChart.chartOptions.title.text = iosTotal.toString();
    }
  }
});
