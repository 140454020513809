











import { androidAreaChart } from '@/data/charts/sparklineCharts';
import Vue from 'vue';
export default Vue.extend({
  name: 'AndroidChart',
  props: {
    deviceData: [],
    totalDownloads: Number
  },
  data() {
    return { androidAreaChart: androidAreaChart };
  },
  created() {
    this.updateChartTitle();
  },
  mounted() {
    this.updateChartTitle();
  },
  methods: {
    updateChartTitle() {
      const androidTotal = this.totalDownloads as number;
      this.androidAreaChart.chartOptions.title.text = androidTotal.toString();
    }
  }
});
