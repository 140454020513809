import { SparklineChart } from './chartTypes';
export const androidAreaChart: SparklineChart = {
  series: [
    {
      name: 'Android Download',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#2196F3', '#fbaf0f'],
    title: {
      text: '0',
      align: 'center',
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: 'Android Downloads',
      align: 'center',
      style: {
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0
      }
    },
    markers: {
      size: 5
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
};

export const iosAreaChart: SparklineChart = {
  series: [
    {
      name: 'IOS Download',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#FF5252', '#fbaf0f'],
    title: {
      text: '0',
      align: 'center',
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: 'IOS Downloads',
      align: 'center',
      style: {
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0
      }
    },
    markers: {
      size: 5
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
};

export const webAreaChart: SparklineChart = {
  series: [
    {
      name: 'Web Download',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ],
  chartOptions: {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#4CAF50', '#fbaf0f'],
    title: {
      text: '0',
      align: 'center',
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: 'Web Downloads',
      align: 'center',
      style: {
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0
      }
    },
    markers: {
      size: 5
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
};
