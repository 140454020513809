



































































import {
  weekBarChart,
  monthsBarChart,
  yearBarChart
} from '@/data/charts/downloadsChart';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { IStatistic, Statistic, StatisticIntervalType } from '@/models';
import { startOfWeek, endOfWeek } from 'date-fns';
import {
  calculateWeekChartData,
  ChartSeries,
  extractTotal
} from '@/data/devicedata';

enum StateType {
  WEEK,
  MONTH,
  YEAR
}

export default Vue.extend({
  name: 'DownloadsChart',
  props: {
    deviceTotals: [],
    androidList: [],
    iosList: [],
    webList: []
  },
  data() {
    return {
      weeklyBarChart: weekBarChart,
      monthlyBarChart: monthsBarChart,
      yearlyBarChart: yearBarChart,
      deviceData: {} as ChartSeries,
      //10/28/2021
      statistics: new Statistic(),
      state: StateType.WEEK,
      StateType
    };
  },
  computed: {
    ...mapGetters({
      getStats: 'statistic/getStatistics'
    }),
    weekstats(): IStatistic {
      return this.getStats;
    }
  },
  async mounted() {
    const currentDay = new Date();
    const fetchStatsParams = {
      created_after: startOfWeek(currentDay).toISOString().replace('Z', ''),
      created_before: endOfWeek(currentDay).toISOString().replace('Z', ''),
      interval: StatisticIntervalType.DAILY
    };
    await this.fetchStats(fetchStatsParams);
    this.statistics = this.weekstats;
    this.gotoWeek();
  },
  methods: {
    ...mapActions({
      fetchStats: 'statistic/fetchStatistics'
    }),
    gotoWeek() {
      this.state = StateType.WEEK;
      const data = calculateWeekChartData(this.statistics);
      this.weeklyBarChart.series = [
        {
          name: 'Android',
          data: data.android as number[]
        },
        {
          name: 'IOS',
          data: data.ios as number[]
        },
        {
          name: 'Web',
          data: data.web as number[]
        }
      ];
    },
    gotoMonth() {
      this.state = StateType.MONTH;
      this.deviceData = {
        android: this.androidList as number[],
        ios: this.iosList as number[],
        web: this.webList as number[]
      };
      this.monthlyBarChart.series = [
        {
          name: 'Android',
          data: this.deviceData.android
        },
        {
          name: 'IOS',
          data: this.deviceData.ios
        },
        {
          name: 'Web',
          data: this.deviceData.web
        }
      ];
    },
    gotoYear() {
      this.state = StateType.YEAR;
      const data = extractTotal(this.deviceTotals as number[]);
      this.yearlyBarChart.series = [
        {
          name: 'App Download',
          data: [data.android, data.ios, data.web]
        }
      ];
    }
  }
});
