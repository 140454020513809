import { IStatistic } from '@/models';

import { startOfWeek, endOfWeek } from 'date-fns';

export function test(): void {
  const currentDay = new Date();
  const fetchStatsParams = {
    created_after: startOfWeek(currentDay).toISOString().replace('Z', ''),
    created_before: endOfWeek(currentDay).toISOString().replace('Z', '')
  };
  console.log(fetchStatsParams);
}

export type ChartSeries = { android: number[]; ios: number[]; web: number[] };
export type NumberSeries = { android: number; ios: number; web: number };

export function calculateDeviceChartsData(stats: IStatistic): ChartSeries {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const androidData = new Map([
    ['Jan', 0],
    ['Feb', 0],
    ['Mar', 0],
    ['Apr', 0],
    ['May', 0],
    ['Jun', 0],
    ['Jul', 0],
    ['Aug', 0],
    ['Sep', 0],
    ['Oct', 0],
    ['Nov', 0],
    ['Dec', 0]
  ]);

  const iosData = new Map([
    ['Jan', 0],
    ['Feb', 0],
    ['Mar', 0],
    ['Apr', 0],
    ['May', 0],
    ['Jun', 0],
    ['Jul', 0],
    ['Aug', 0],
    ['Sep', 0],
    ['Oct', 0],
    ['Nov', 0],
    ['Dec', 0]
  ]);

  const webData = new Map([
    ['Jan', 0],
    ['Feb', 0],
    ['Mar', 0],
    ['Apr', 0],
    ['May', 0],
    ['Jun', 0],
    ['Jul', 0],
    ['Aug', 0],
    ['Sep', 0],
    ['Oct', 0],
    ['Nov', 0],
    ['Dec', 0]
  ]);

  const currentYear = new Date().getFullYear();

  stats.details.forEach((detail) => {
    if (detail.start_date) {
      const hold = new Date(detail.start_date);
      if (hold.getFullYear() == currentYear) {
        const month = months[hold.getMonth()];
        androidData.set(month, detail.statistics.android);
        iosData.set(month, detail.statistics.ios);
        webData.set(month, detail.statistics.web);
      }
    }
  });
  const data = {
    android: Array.from(androidData.values()),
    ios: Array.from(iosData.values()),
    web: Array.from(webData.values())
  };
  return data;
}

export function extractTotal(data: number[]): NumberSeries {
  const results = { android: data[0], ios: data[1], web: data[2] };
  return results;
}

export function calculateWeekChartData(stats: IStatistic): ChartSeries {
  const androidData = new Map([
    ['Sun', 0],
    ['Mon', 0],
    ['Tues', 0],
    ['Wed', 0],
    ['Thur', 0],
    ['Fri', 0],
    ['Sat', 0]
  ]);

  const iosData = new Map([
    ['Sun', 0],
    ['Mon', 0],
    ['Tues', 0],
    ['Wed', 0],
    ['Thur', 0],
    ['Fri', 0],
    ['Sat', 0]
  ]);

  const webData = new Map([
    ['Sun', 0],
    ['Mon', 0],
    ['Tues', 0],
    ['Wed', 0],
    ['Thur', 0],
    ['Fri', 0],
    ['Sat', 0]
  ]);

  const currentYear = new Date().getFullYear();
  const weekdays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  stats.details.forEach((detail) => {
    if (detail.start_date) {
      const hold = new Date(detail.start_date);
      if (hold.getFullYear() == currentYear) {
        const day = weekdays[(hold.getDay() + 1) % weekdays.length];
        androidData.set(day, detail.statistics.android);
        iosData.set(day, detail.statistics.ios);
        webData.set(day, detail.statistics.web);
      }
    }
  });
  const data = {
    android: Array.from(androidData.values()),
    ios: Array.from(iosData.values()),
    web: Array.from(webData.values())
  };
  return data;
}
