










import { webAreaChart } from '@/data/charts/sparklineCharts';
import Vue from 'vue';
export default Vue.extend({
  name: 'WebChart',
  props: {
    deviceData: [],
    totalDownloads: Number
  },
  data() {
    return { webAreaChart: webAreaChart };
  },
  created() {
    this.updateChartTitle();
  },
  mounted() {
    this.updateChartTitle();
  },
  methods: {
    updateChartTitle() {
      const webTotal = this.totalDownloads as number;
      this.webAreaChart.chartOptions.title.text = webTotal.toString();
    }
  }
});
